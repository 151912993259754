import React from "react";
import { Route, Switch } from "react-router-dom";
import BoreholeUpload from './BoreholeUpload';
import AddBorehole from './AddBorehole';
import EditBorehole from './EditBorehole';
import BoreholeList from './BoreholeList';
import BoreholeLogViewer from './BoreholeLogViewer';
import ImageViewer from '../../components/image-viewer/image-viewer';
import MapViewer from '../../components/map-viewer/container';
import { PrivateRoute } from "../../components/privateRoute";

const BoreholeRoutes = ({setBorehole}: any) => {
  return (
    
    <Switch>
      <PrivateRoute exact path="/client/:clientId/project/:projectId/borehole">
        <BoreholeList setBorehole={setBorehole}/>
      </PrivateRoute>          
      <PrivateRoute exact path="/client/:clientId/project/:projectId/borehole/:boreholeId/tracks">
        <BoreholeLogViewer setBorehole={setBorehole}/>
      </PrivateRoute>          
      <PrivateRoute exact path="/client/:clientId/project/:projectId/borehole/:boreholeId/log-viewer">
        <BoreholeLogViewer setBorehole={setBorehole}/>
      </PrivateRoute>          
      <PrivateRoute exact path="/client/:clientId/project/:projectId/borehole/:boreholeId/log-viewer/:templateId">
        <BoreholeLogViewer setBorehole={setBorehole}/>
      </PrivateRoute>
      <PrivateRoute exact path="/client/:clientId/project/:projectId/borehole/:boreholeId/log-viewer/view/:viewId">
        <BoreholeLogViewer setBorehole={setBorehole}/>
      </PrivateRoute>
      <PrivateRoute exact path="/client/:clientId/project/:projectId/borehole/add">
        <AddBorehole setBorehole={setBorehole}/>
      </PrivateRoute>          
      <PrivateRoute exact path="/client/:clientId/project/:projectId/borehole/:boreholeId/files">
        <BoreholeUpload setBorehole={setBorehole}/>
      </PrivateRoute>
      <PrivateRoute exact path="/client/:clientId/project/:projectId/borehole/:boreholeId/edit">
        <EditBorehole setBorehole={setBorehole}/>
      </PrivateRoute>      
      <PrivateRoute exact path="/client/:clientId/project/:projectId/borehole/:boreholeId/image-viewer">
        <ImageViewer setBorehole={setBorehole}/>
      </PrivateRoute>
      <PrivateRoute exact path="/client/:clientId/project/:projectId/borehole/:boreholeId/image-viewer/:templateId">
        <ImageViewer setBorehole={setBorehole}/>
      </PrivateRoute>
      <PrivateRoute exact path="/client/:clientId/project/:projectId/map-viewer">
        <MapViewer setBorehole={setBorehole}/>
      </PrivateRoute>
      {/* <PrivateRoute exact path="/client/:clientId">
        <BoreholeList setBorehole={setBorehole}/>
      </PrivateRoute> */}
      <PrivateRoute exact path="/">
        <BoreholeList setBorehole={setBorehole}/>
      </PrivateRoute>
    </Switch>                   
    
  );
};

export default BoreholeRoutes;
